export class WebIdentificacaoUsuario {
  idWebIdentificacaoWeb: number;
  idPessoa: number;
  idPlano: number;
  dataGeracao: Date;
  rotina: string;
  token: string;
  dataUtilizacao: Date;
  valido: string;
}
