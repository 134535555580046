import { EscolhaPessoaService } from "./../shared/services/escolhaPessoa.service";
import { Util } from "./../shared/util";
import { PerfilSelecaoParticipante } from "./../shared/models/perfilSelecaoParticipante.model";
import { PerguntaPerfilService } from "./../shared/services/perguntaPerfil.service";
import { ViewModelPergunta } from "./../shared/models/viewModelPergunta.model";
import { WebIdentificacaoUsuarioStore } from "./../shared/store/webIdentificacaoUsuario.store";
import { Component, OnInit } from "@angular/core";
import { SugestaoPerfil } from "../shared/models/sugestaoPerfil.model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AlertaToastrService } from "../shared/services/alertaToastr.service";
import { ViewModelEscolhaPessoa } from "../shared/models/viewModelEscolhaPessoa.model";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material";
import { DialogResultadoComponent } from "./dialog-resultado/dialog-resultado.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-resultado",
  templateUrl: "./resultado.component.html",
  styleUrls: ["./resultado.component.scss"],
})
export class ResultadoComponent implements OnInit {
  sugestaoPerfil = new SugestaoPerfil();
  viewModelPergunta = new ViewModelPergunta();
  viewModelEscolhaPessoa = new ViewModelEscolhaPessoa();
  listaPerfilSelecaoParticipante: PerfilSelecaoParticipante[] = [];
  perfilSelecionado: number;
  form: FormGroup;
  mensagem: string;
  loading = true;
  cliente: string = environment.cliente;

  constructor(
    private formBuilder: FormBuilder,
    private webIdentificacaoUsuarioStore: WebIdentificacaoUsuarioStore,
    private perguntaPerfilService: PerguntaPerfilService,
    private toastrService: AlertaToastrService,
    private util: Util,
    public dialog: MatDialog,
    private escolhaPessoaService: EscolhaPessoaService
  ) {
    webIdentificacaoUsuarioStore
      .getCurrent()
      .subscribe((webIdentificacaoUsuario) => {
        if (webIdentificacaoUsuario) {
          this.viewModelPergunta.idPessoa = webIdentificacaoUsuario.idPessoa;
          this.viewModelPergunta.idPlano = webIdentificacaoUsuario.idPlano;
          this.viewModelPergunta.numeroPergunta = 0;

          perguntaPerfilService
            .resultadoPerguntaPerfil(this.viewModelPergunta)
            .subscribe((sugestaoPerfil) => {
              if (sugestaoPerfil) {
                this.sugestaoPerfil = sugestaoPerfil;
                this.loading = false;
              }
            });

          perguntaPerfilService
            .perfilSelecaoParticipante(webIdentificacaoUsuario.idPlano)
            .subscribe((listaPerfilSelecaoParticipante) => {
              if (listaPerfilSelecaoParticipante) {
                this.listaPerfilSelecaoParticipante = listaPerfilSelecaoParticipante;
              }
            });
        }
      });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      PerfilSelecionado: [""],
    });
  }

  finalizar() {
    this.validaResultado((resultado) => {
      if (resultado) {
        this.viewModelEscolhaPessoa.idPessoa = this.viewModelPergunta.idPessoa;
        this.viewModelEscolhaPessoa.idPlano = this.viewModelPergunta.idPlano;
        this.viewModelEscolhaPessoa.dataResposta = new Date();
        this.viewModelEscolhaPessoa.idPerfilSugerido = this.sugestaoPerfil.idPerfil;
        this.viewModelEscolhaPessoa.idPerfilEscolhido = this.perfilSelecionado;

        this.escolhaPessoaService
          .postEscolhaPessoa(this.viewModelEscolhaPessoa)
          .subscribe(
            () => {
              this.dialog.open(DialogResultadoComponent, {
                width: "55%",
                height: "auto",
              });
            },
            (erro) => {
              this.mensagem = "Erro ao salvar o perfil: " + erro;
              this.toastrService.showToasterError(this.mensagem, "Erro!");
            }
          );
      }
    });
  }

  validaResultado(callback) {
    if (this.util.IsNullOrUndefined(this.perfilSelecionado)) {
      this.mensagem = "É necessário escolher um perfil antes de finalizar!";
      this.toastrService.showToasterWarning(this.mensagem, "Aviso");
      callback(false);
    } else {
      callback(true);
    }
  }
}
